#admin-chat-root div,
#admin-chat-root span {
  font-family: "Noto Sans KR";
}

.admin-chat-root {
  padding-bottom: 0px;
  z-index: 600;
  /* width: calc(var(--footer-left-size) + 32px); */
  /* width: 80%; */
}

.admin-chat-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.admin-chat-list::-webkit-scrollbar {
  display: none;
}

.admin-chat-list-pc::-webkit-scrollbar {
  display: none;
}

.btn-new-chat {
  position: absolute;
  /* top: 100%;
  left: 50%; */
  bottom: 5px;
  right: 25%;
  /* transform: translate(-50%, -183px); */
  z-index: 901;
}

.chat-input-root {
  filter: drop-shadow(2px 4px 6px #494949);
}

.chat-input-root-pc {
  filter: drop-shadow(0px 0px 9px #49494940);
  border-end-start-radius: 0.5rem;
  border-end-end-radius: 0.5rem;
}

.speech-bubble {
  position: relative;
  padding: 10px;
  background: #2e2f34;
  border-radius: 0.4em;
  color: #fff;
  max-width: 100%;
  white-space: pre-wrap;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #2e2f34;
  border-left: 0;
  border-top: 0;
  margin-top: -15.5px;
  margin-left: -10px;
}

.speech-bubble-me {
  position: relative;
  padding: 10px;
  background: #024d5e;
  border-radius: 0.4em;
  color: #fff;
  max-width: 100%;
  white-space: pre-wrap;
}

.speech-bubble-me:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #024d5e;
  border-right: 0;
  border-top: 0;
  margin-top: -15.5px;
  margin-right: -10px;
}
.btn-admin-new-chat {
  position: absolute;
  bottom: 5px;
  right: 37%;
  z-index: 901;
}

.live-content-chat {
  display: flex;
  align-items: flex-end;
  margin-bottom: 3px;
}

.live-channel-info-pc-chat {
  height: 91%;
}

.chat-list-body {
  text-align: left;
  display: flex;
}

.quote-Container {
  padding: 5px 19px 5px 19px;
  box-sizing: border-box;
  background-color: #bcc8d4;
  border-radius: 20px;
  min-height: 50px;
  margin-top: 10px;
}

#admin-chat-input {
  padding: 20px 10px;
  height: 41px;
  background-color: #ffffff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

#admin-chat-input fieldset {
  border: hidden;
}

#btn-admin-send-chat {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ff376d;
}

.admin-prod-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.admin-prod-list::-webkit-scrollbar {
  display: none;
}
