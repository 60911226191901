.admin-prod-text {
  width: 226px;
  font-size: 14px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.admin-prod-text-2line {
  font-size: 12px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 36px;
}
