html,
body {
  width: 100%;
  height: 100%;
  /* background-color: #efefef; */
}

.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 자주 쓰는 단위 CSS */
.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.width-100 {
  width: 100%;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 45px;
}

/* Left Menu*/
.menu-text {
  color: #fff;
  font-weight: 700 !important;
  font-size: 1.1rem !important;
}

.menu-text-active {
  color: #212121;
  font-weight: 700 !important;
  font-size: 1.1rem !important;
}

.sub-menu-text {
  color: #fff;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.sub-menu-text-active {
  color: #212121;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.make-new-press-btn {
  justify-content: flex-start;
  color: #2e3341 !important;
  font-size: 14px !important;
  background-color: #ffffff !important;
  border-radius: 7px !important;
}

.make-new-press-btn:hover {
  color: #fff !important;
  background-color: #fff5 !important;
}

.logo-menu-title {
  margin-bottom: 8px !important;
  font-size: 14px !important;
  font-weight: 800 !important;
}

/* 나의 진행사항 */
.card-my-progress-paper {
  width: 100%;
  border-radius: 7px !important;
  background-color: #00000030 !important;
  color: #ffffff !important;
  padding: 16px;
  box-sizing: border-box;
}

.card-my-progress-statistic {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.card-my-progress-statistic-text {
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  opacity: 0.5;
}

.card-my-progress-linear {
  background-color: #fff3 !important;
  height: 2px !important;
}

.card-my-progress-linear.MuiLinearProgress-bar {
  background-color: #a7bef4 !important;
}

/* 로딩 모달 */
.modal-loading {
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1038;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.load-cont {
  margin-top: -100px;
}

.spinner-wrap {
  display: flex;
  justify-content: center;
  height: 65px;
}
.spinner {
  width: 12px;
  height: 12px;
  animation: spinner-o824ag 1s infinite linear;
}
.spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff6c58;
  border-radius: 50%;
  animation: spinner-vse6n7 1.25s infinite ease;
}
.spinner div:nth-child(1) {
  --rotation: 90;
}
.spinner div:nth-child(2) {
  --rotation: 180;
}
.spinner div:nth-child(3) {
  --rotation: 270;
}
.spinner div:nth-child(4) {
  --rotation: 360;
}
@keyframes spinner-vse6n7 {
  0%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
  }
}

@keyframes spinner-o824ag {
  to {
    transform: rotate(360deg);
  }
}

.datatable-mng-tenants {
  height: 631px;
}

.tenants-list-pagenation {
  display: flex;
  width: 100%;
  justify-content: center;
}

.left-user-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 8px;
}

.left-list-item {
  margin: 0 0 2px;
}

.left-icon-btn {
  min-width: 40px !important;
}

.left-icons {
  color: rgba(255, 255, 255, 0.25);
}

.left-footer-menu {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
}

.btn-left-sign-out {
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: snow;
}

.my-account-header-root {
  height: 5rem;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  padding-left: 48px;
  padding-right: 48px;
  align-items: center;
}

.my-account-header-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(3, 0, 128);
}

.my-account-content-sub-title {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.my-account-content-root {
  overflow: auto;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 24px;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .my-account-content-root {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }
}

.apikey-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 16px;
}

.sub-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 16px;
}

.data-table-header {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: rgba(0, 0, 0, 0.6);
}

.data-table-img-button {
  color: rgba(100, 100, 255, 0.8);
}

.color-red {
  color: red;
}

.my-account-setting-detail-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.setting-detail-sub-title {
  min-width: 150px;
  color: rgba(0, 0, 0, 0.5);
}

.video-detail-info-row {
  display: flex;
  flex-direction: row;
}

.video-detail-info-title {
  min-width: 150px;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.9);
  line-height: 2.5 !important;
}

.video-detail-embed-title {
  color: rgba(0, 0, 0, 0.7);
  line-height: 2.5 !important;
}

.w-20 {
  width: 20px;
}

.main-video {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: no-repeat center center;
  background-size: cover;
}

.main-copy {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ta-center {
  text-align: center;
}

.roboto {
  font-family: "Roboto", sans-serif;
}
.fw-300 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 800;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.color-white {
  color: #ffffff;
}

.fs-big {
  font-size: 3.5rem;
}

.fs-normal {
  font-size: 1.3rem;
}

.ml-0 {
  margin-left: 0;
}

.info-main-header-root {
  height: 5rem;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  padding-left: 48px;
  padding-right: 48px;
  align-items: center;
}

.info-main-header-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(3, 0, 128);
}

.info-main-content-root {
  overflow: auto;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 64px;
  height: 100%;
}

.info-main-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 16px;
}

.info-main-content-title {
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.info-main-content {
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 16px;
}

.info-main-content-sub-root {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.info-main-content-sub-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: rgb(3, 0, 128);
}

.info-main-request-body-title {
  font-size: 1rem !important;
  font-weight: bold !important;
  line-height: 1.4 !important;
}

.info-main-request-body-text {
  font-size: 1rem !important;
  line-height: 1.4 !important;
}

.info-main-request-body-text-sub {
  font-size: 1rem !important;
  line-height: 1.4 !important;
  padding-left: 20px;
}

.vector-document-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: rgb(47, 96, 164);
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.sub-header-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-header-title {
  font-size: 1.2rem !important;
  font-weight: 800 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #3a3a3a;
  margin-bottom: 0 !important;
}

.sub-content-root {
  overflow: hidden;
  height: 100%;
  position: relative;
  padding-top: 24px;
}

.template-content-title {
  padding: 8px;
  width: 30%;
  box-sizing: border-box;
  border: 1px solid #abb2b9;
  border-radius: 0.5rem 0 0 0.5rem;
  background-color: #bacbf670;
  text-align: center;
  line-height: 1.4rem;
}

.template-content-content {
  padding: 0px;
  width: 70%;
  box-sizing: border-box;
  border: 1px solid #abb2b9;
  border-left: 0px;
  border-radius: 0 0.5rem 0.5rem 0;
}

.template-content-content fieldset {
  border: hidden;
}

.div-template-add {
  margin-top: 1rem;
}

.text-template-add {
  width: 70%;
  box-sizing: border-box;
  border: 1px solid #527fed;
  border-right: 0;
  border-radius: 0.5rem 0 0 0.5rem;
}

.text-template-add fieldset {
  border: hidden;
}

.btn-template-add {
  padding: 0px;
  width: 30%;
  box-sizing: border-box;
  border: 1px solid #527fed;
  border-radius: 0 0.5rem 0.5rem 0;
  background-color: #527fed;
}

.template-form-list {
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-radius: 7px;
  overflow-y: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.template-form-list::-webkit-scrollbar {
  display: none;
}

.template-form-list-make {
  margin-top: 1rem;
  margin-bottom: 2rem;
  max-height: calc(80vh - 279px);
  overflow-y: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.template-form-list-make::-webkit-scrollbar {
  display: none;
}

.template-footer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: center;
}

.result-content {
  height: calc(100% - 100px);
  overflow-y: auto;
}

.result-content::selection {
  background-color: #b2d5fd;
}

.selection-text {
  background-color: #b2d5fd;
}

.new-line {
  white-space: pre-wrap;
}

.small-more-info {
  font-family: "Pretendard", sans-serif;
  margin-bottom: 4px;
  color: #7a7a7a;
  font-size: 0.8rem !important;
}

.txt-my-workspace {
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  color: inherit;
  word-break: keep-all;
  font-weight: 800 !important;
}
.logo-root {
  margin: 16px 0;
  padding: 0 1rem;
}
.logo-a {
  display: block;
  width: 220px;
  /* height: 40px; */
}

.logo-img {
  display: block;
  width: 90px;
}

.left-menu-root {
  padding: 0 1rem !important;
  margin-top: 2rem !important;
  color: #fff !important;
}

.left-menu-icon {
  width: 16px !important;
  height: 16px !important;
  object-fit: contain;
}

.left-menu-text {
  color: #fff;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.left-menu-wrapper {
  border-radius: 7px;
  opacity: 0.5;
  height: 40px;
}
.left-menu-wrapper:hover {
  text-decoration: none;
  background-color: rgb(174, 197, 254, 0.15) !important;
  border-radius: 7px;
  opacity: 1;
  height: 40px;
}
.left-menu-wrapper.Mui-selected {
  text-decoration: none;
  background-color: rgb(174, 197, 254, 0.15) !important;
  border-radius: 7px;
  opacity: 1;
  height: 40px;
}

.my-workspace-root {
  padding: 0 1rem !important;
  margin-top: 20px !important;
  color: #fff !important;
}

.new-workspace-root {
  padding: 0 1rem !important;
  margin-top: 4rem !important;
  color: #fff !important;
}

.shake {
  transform-origin: 50% 0%;
  animation-name: shake;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 0s;
  color: #ff9b58;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
    color: #e3ff58;
  }
  10% {
    transform: rotate(15deg);
    color: #e3ff58;
  }
  20% {
    transform: rotate(-15deg);
    color: #e3ff58;
  }
  30% {
    transform: rotate(10deg);
    color: #e3ff58;
  }
  40% {
    transform: rotate(-10deg);
    color: #e3ff58;
  }
  50% {
    transform: rotate(2deg);
    color: #e3ff58;
  }
  60% {
    transform: rotate(-2deg);
    color: #e3ff58;
  }
  70% {
    transform: rotate(0deg);
    color: #e3ff58;
  }
  100% {
    transform: rotate(0deg);
  }
}
.theme-warper {
  overflow: hidden;
}
.theme-masonry-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.theme-masonry-scroll::-webkit-scrollbar {
  display: none;
}

.theme-select-scroll {
  overflow-y: auto;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.theme-select-scroll::-webkit-scrollbar {
  display: none;
}

.search-bar-wrapper {
  width: 60%;
  box-sizing: border-box;
  border: 1px solid #527fed;
  border-radius: 5rem;
  padding: 4px 14px 4px 14px;
}

.search-bar-wrapper fieldset {
  border: hidden;
}

.search-result-view {
  color: #444447;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.upload-video-player {
  max-height: 600px;
}

.card-video-player {
  width: 100%;
}

.card-title {
  font-weight: 700;
  font-size: 14px;
  padding: 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.viewer-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent !important;
  height: 100%;
  z-index: 901;
  overflow: hidden;
}

.Live-Layout-wrapper {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-layout {
  overflow: auto;
  font-size: 0;
  text-align: center;
}

.Live-Layout-inner {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}

.small-layout-inner {
  display: inline-block;
  width: 100%;
  max-width: 56.25vh;
  text-align: left;
}

.Live-Swipe-content {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
}

.VideoPlayer-wrapper {
  position: relative;
  height: 100%;
  overscroll-behavior: contain;
  overflow: hidden;
  z-index: 1;
}

.VideoPlayer-wrapper-control {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
}

.color-snow {
  color: snow;
}

.video-player {
  height: 100%;
}

.client-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  min-width: 100%;
  max-width: 100%;
  background-color: black;
  object-fit: cover;
  z-index: 800;
}
.client-video iframe,
.client-video object,
.client-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-controls {
  position: absolute;
  left: 0.8rem;
  right: 0.8rem;
  bottom: 0.8rem;
  transition: all 0.2s ease;
  z-index: 801;
  padding: 2px 4px 2px 4px;
  background-color: #00000070;
  border-radius: 7px;
}

.video-controls.hide {
  opacity: 0;
  pointer-events: none;
}

.DragDrop {
  width: 100%;
  height: 50vh;
  z-index: 999;
}

.video-detail-header-root {
  height: 5rem;
  min-height: 5rem;
  display: flex;
  justify-content: flex-start;
  padding-left: 48px;
  padding-right: 48px;
  align-items: center;
}

.video-detail-content-root {
  overflow: auto;
  padding-right: 24px;
  padding-bottom: 24px;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .video-detail-content-root {
    padding-right: 24px;
    padding-bottom: 64px;
  }
}

.chart-root {
  width: 100%;
  height: 100%;
}

.statistic-chart-root {
  width: 100%;
  height: 400px;
}

.content-header-root {
  height: 4rem;
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
}

.content-header-root-mo {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  align-items: center;
}

.content-header-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(3, 0, 128);
}

.content-header-title-mo {
  font-size: 1rem !important;
  font-weight: 700 !important;
  white-space: pre-line;
  color: rgb(3, 0, 128);
}

.content-content-root {
  overflow: auto;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .content-content-root {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }
}

.content-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
}

.frame-root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.statisticinfo-line-chart-root {
  width: 100%;
  height: 400px;
}

.line-through {
  text-decoration: line-through;
}

.color-gray {
  color: gray;
}

.color-lightgray {
  color: lightgray;
}
