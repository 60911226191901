.notice-editor-wrapper {
  width: 500px;
}
.notice-editor {
  height: 500px;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.live-notice-content {
  font-family: Noto Sans KR !important;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-all;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

.live-notice-content::-webkit-scrollbar {
  display: none;
}
