.adminCouponList {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  padding: 1px;
}

.adminCouponList::-webkit-scrollbar {
  display: none;
}

.cpnItemBox {
  display: flex;
  flex-direction: column;
  height: 90px;
  padding: 8px;
  cursor: pointer !important;
}

.cpnImgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.cpnImgBox img {
  width: 150px;
  height: 80px;
  border-radius: 7px;
  object-fit: contain;
}

.cpnImgTextStack {
  height: 100%;
  position: absolute;
  top: 1px;
  left: 10px;
  color: white;
  display: flex;
  justify-content: center;
}

.cpnNormalText {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

}

.cpnBoldText {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.cpnSmallText {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;

  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.cpnDescBox {
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
  height: 100%;
  padding: 0px 10px;
  justify-content: center;
}
.cpnDescStack {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}